<template>
  <div class="formdata" style="padding: 20px">
    <el-form ref="form" :model="form" :rules="rules" label-width="170px">
      <el-form-item v-if="type !== 3" label="系统随访计划模板编码:" prop="id">
        <el-input
          v-model="form.id"
          v-check-permission
          placeholder="请输入系统随访计划模板编码"
          disabled
          style="width: 30%"
          maxlength="10"
        />
      </el-form-item>
      <el-form-item label="系统随访计划模板名称:" prop="name">
        <el-input
          v-model="form.name"
          v-check-permission
          :disabled="type === 0"
          placeholder="请输入系统随访计划模板名称"
          style="width: 30%"
          maxlength="20"
        />
      </el-form-item>
      <div
        v-for="(item, index) in form.nodeList"
        :key="index"
        class="followUp-node"
      >
        <div class="index">
          <div>随访节点{{ index + 1 }}</div>
          <div v-if="index !== 0" class="opertor">
            <el-button
              type="text"
              size="mini"
              :disabled="type === 0"
              @click="delFollowUpNode(index)"
            >
              删除
            </el-button>
            <el-button type="text" size="mini" @click="showMore(index)">
              展开/收起
            </el-button>
          </div>
        </div>
        <div :id="index">
          <el-form-item label="添加起始时间后:" required>
            <el-col :span="9">
              <el-form-item
                :prop="`nodeList.${index}.interviewTime`"
                :rules="rules.interviewTime"
              >
                <el-input v-model="item.interviewTime" :disabled="type === 0" />
              </el-form-item>
            </el-col>
            <el-col :span="1"> &nbsp; </el-col>
            <el-col :span="10">
              <el-form-item
                :prop="'nodeList.' + index + '.interviewUnit'"
                :rules="rules.interviewUnit"
              >
                <!-- <el-radio-group v-model="item.interviewUnit"> -->
                <el-radio
                  v-model="item.interviewUnit"
                  label="week"
                  :disabled="type === 0"
                >
                  周
                </el-radio>
                <el-radio
                  v-model="item.interviewUnit"
                  label="month"
                  :disabled="type === 0"
                >
                  月
                </el-radio>
                <!-- </el-radio-group> -->
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item
            label="添加随访项目:"
            :prop="`nodeList[${index}].projectList`"
          >
            <!-- <span
              v-if="isShowTip"
              style="color: red;"
            >请勾选随访项目</span> -->
            <el-checkbox-group v-model="item.projectList">
              <div class="checkBoxStyle">
                <div v-for="item1 in allProject" :key="item1.id">
                  <el-checkbox :label="item1.id" :disabled="type === 0">
                    {{ item1.name }}
                  </el-checkbox>
                </div>
              </div>
            </el-checkbox-group>
          </el-form-item>
          <!-- :prop="`nodeList[${index}].projectList`" -->
        </div>
      </div>
      <el-form-item>
        <el-button
          v-if="type !== 0"
          type="primary"
          size="mini"
          @click="addNode()"
        >
          继续添加
        </el-button>
      </el-form-item>

      <el-form-item>
        <el-button
          v-if="type !== 0"
          type="primary"
          :disabled="loading"
          @click="saveTemplate()"
        >
          保存
        </el-button>
        <el-button @click="goBack"> 返回 </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// import { mapActions } from 'vuex';
import { codeRule, positiveInteger } from '@/utils/verificationRule.js';

var nodeData = {
  interviewTime: '',
  interviewUnit: '',
  projectList: [], // 随访勾选项目列表
};

export default {
  name: 'Details',
  data() {
    return {
      form: {
        id: '',
        name: '',
        nodeList: [{ ...nodeData }],
      },
      isShowTip: false, // 未勾选随访项目时提示语
      isShowMore: true, // 将节点展开
      allProject: [], // 项目列表
      loading: false, // 点击保存，按钮置灰
      rules: {
        id: [{ required: true, validator: codeRule, trigger: 'blur' }],
        name: [
          { required: true, message: '请输入问诊类型名称', trigger: 'blur' },
        ],
        interviewUnit: [
          { required: true, message: '请勾选周或月', trigger: 'change' },
        ],
        interviewTime: [
          { required: true, message: '请输入时间', trigger: 'blur' },
          { required: true, validator: positiveInteger, trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    type() {
      return this.$route.query.type;
    },
  },
  watch: {},
  async created() {
    await this.getProjectList();
  },
  mounted() {
    if (this.type !== 3) {
      this.queryTemplateDetail();
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // 查询随访项目列表
    getProjectList() {
      this.$api.interviewProjectList({}).then((res) => {
        // this.form.nodeList[0].projectList = res.data;
        this.allProject = res.data;
        console.log(nodeData, 'bode');
      });
    },
    // 增加随访节点
    addNode() {
      // nodeData.projectList = this.allProject;
      nodeData = {
        interviewTime: '',
        interviewUnit: '',
        projectList: [], // 随访勾选项目列表
      };
      this.form.nodeList.push(nodeData);
    },
    // 删除随访节点
    delFollowUpNode(index) {
      this.form.nodeList.splice(index, 1);
    },
    // 展开/收起
    showMore(id) {
      this.isShowMore = !this.isShowMore;
      this.contentShow(id);
    },
    // 内容显示隐藏
    contentShow(id) {
      var nodeId = document.getElementById(id);
      console.log(nodeId);
      if (this.isShowMore) {
        nodeId.style.display = '';
      } else {
        nodeId.style.display = 'none';
      }
    },
    // 保存模板
    saveTemplate() {
      const { type, form, allProject } = this;
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          const params = {
            ...JSON.parse(JSON.stringify(form)),
            orgCode: this.$route.query.orgCode,
          };
          params.nodeList.map((v) => {
            v.list = [];
            v.projectList.map((v1) => {
              allProject.forEach((v2) => {
                if (v2.id === v1) {
                  var node = {
                    interviewProjectId: v2.id,
                    interviewProjectName: v2.name,
                  };
                  return v.list.push(node);
                }
              });
            });
          });
          params.nodeList.map((v) => (v.projectList = v.list));
          params.nodeList.forEach((v) => {
            delete v.list;
          });
          console.log(params);
          // delete params.
          if (type === 3) {
            this.addTemplate(params);
          } else {
            this.editTemplate(params);
          }
        } else {
          return false;
        }
      });
    },
    // 新增模板接口
    addTemplate(param) {
      this.$api.interviewCreate(param).then((res) => {
        if (res.code === 0) {
          this.$message.success('新增随访模板成功');
          this.goBack();
        }
      });
    },
    // 编辑模板接口
    editTemplate(param) {
      this.$api.interviewUpdate(param).then((res) => {
        if (res.code === 0) {
          this.$message.success('更新随访模板成功');
          this.goBack();
        }
      });
    },
    // 查询随访模板详情
    queryTemplateDetail() {
      const param = {
        id: this.$route.query.id,
      };
      this.$api.interviewDetail(param).then((res) => {
        this.form = res.data;
        this.form.nodeList.map((v) => {
          v.list = [];
          v.projectList.forEach((v1) => {
            v.list.push(v1.interviewProjectId);
          });
        });
        this.form.nodeList.forEach((v) => {
          v.projectList = v.list;
        });
      });
    },
  },
};
</script>

<style lang="scss">
// 预约时间的可预约样式
</style>
<style lang="scss" scoped>
.formdata {
  label {
    font-size: 14px;
    color: #606266;
  }

  .input_el {
    width: 30%;
  }
}
.labelStyle {
  margin-right: 10px;
}
.followUp-node {
  width: 1000px;
  margin-bottom: 50px;
  .index {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-left: 20px;
    margin-right: 100px;
    // font-size: 16px;
  }
  .checkBoxStyle {
    height: 200px;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 20px;
  }
}
</style>
